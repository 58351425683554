<template>
  <div>
    <v-form>
      <p class="mb-n3">
        What is the limiting reagent in Part A, and approximately how many moles of the excess
        reagent will remain after the reaction is complete?
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemMcMPL4_Q8',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {
          text: 'HCl is limiting with $5 \\times 10^{-3}\\,\\text{mol}$ of Mg remaining unreacted',
          value: 'a',
        },
        {
          text: 'HCl is limiting with $2.5 \\times 10^{-3}\\,\\text{mol}$ of Mg remaining unreacted',
          value: 'b',
        },
        {
          text: 'Mg is limiting with $2.5 \\times 10^{-3}\\,\\text{mol}$ of HCl remaining unreacted',
          value: 'c',
        },
        {
          text: 'Mg is limiting with $5 \\times 10^{-3}\\,\\text{mol}$ of HCl remaining unreacted',
          value: 'd',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
